import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tableConfiguration } from '../user-management-table/user-management-table.component';
import { UserManagementServiceService } from '../user-management-service.service';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
  tableHeading = [
    {title: '', code: 'User Name', key: 'name' , subKey: '', type:'text', sort: true, isClickable:true},
    {title: '', code: 'User Role', key: 'role' , subKey: 'name', type:'text', sort: false, isClickable:false},
    {title: '', code: 'Country', key: 'country' , subKey: 'name', type:'text', sort: false, isClickable:false,imageKey:true},
    {title: '', code: 'IP', key: 'industrialpark' , subKey: 'name', type:'text', sort: false, isClickable:false},
    {title: '', code: 'Factory', key: 'factory' , subKey: 'name', type:'text', sort: false, isClickable:false},
    {title: '', code: 'Creation Date', key: 'createdAt' , subKey: '', type:'date', sort: false, isClickable:false},
    {title: '', code: 'Actions', key: 'action' , subKey: '', type:'text', sort: false, isClickable:false},
  ]
  tableHeading1 = [
    {title: '', code: 'User Name', key: 'name' , subKey: '', type:'text', sort: true, isClickable:true},
    {title: '', code: 'User Role', key: 'role.name' , subKey: 'name', type:'text', sort: true, isClickable:false},
    {title: '', code: 'Email', key: 'email' , subKey: '', type:'text', sort: false, isClickable:false},
    {title: '', code: 'Country', key: 'country.name' , subKey: 'name', type:'text', sort: true, isClickable:false,imageKey:true},
    {title: '', code: 'IP', key: 'industrialpark.name' , subKey: 'name', type:'text', sort: true, isClickable:false},
    {title: '', code: 'Factory', key: 'factory.name' , subKey: 'name', type:'text', sort: true, isClickable:false},
    {title: '', code: 'Creation Date', key: 'createdAt' , subKey: '', type:'date', sort: true, isClickable:false},
    {title: '', code: 'Actions', key: 'action' , subKey: '', type:'text', sort: false, isClickable:false},
  ]
  totalCount = 0;
  tableData: any;
  manageUsertableData1: any = [];
  action=[
    {actionType:'edit',icon:'fa fa-edit',disabled:false, toolTip:'Edit',imgIcon:'../../../../assets/images/edit.svg',isPermission:false,label:'Update',key:'update'},
    {actionType:'delete',icon:'fa fa-trash',disabled:false,toolTip:'Delete',imgIcon:'../../../../assets/images/delete-bin.svg',isPermission:false,label:'Delete',key:'delete'},
    {actionType:'view',icon:'fa fa-eye',disabled:false,toolTip:'View',imgIcon:'../../../../assets/images/view-icon.svg',isPermission:true,label:'View',key:'view'},
  ]
  allowedAction:any;
  constructor(
    private router: Router,
    private userManagementService : UserManagementServiceService,
    private snackBar:MatSnackBar,
  ) { 
    let pageAction = JSON.parse(sessionStorage.getItem('userPermission')!);
    this.allowedAction = pageAction[0].authenicationJson.filter((el:any)=>{
      if(el?.model === 'user') return el;
    })
  }

  ngOnInit(): void {
    console.log("allowed",this.allowedAction);
    this.action = this.userManagementService.actionTablePermission(this.action,this.allowedAction[0]);
    console.log("action",this.action);
    this.getAllUser();
  }
  getAllUser(page = 1,limit=10,orderSorting:string='',columnName:string=''):void{
    this.userManagementService.allUserDetails(page,limit,orderSorting,columnName).subscribe((res:any) => {
      if(res.success){
        // this.openSnackBar([res?.message || 'Success !'] , 3000);
        console.log(res.data,"1")
        this.manageUsertableData1 = JSON.parse(JSON.stringify(res.data));
        this.totalCount = res.total ? res.total : 0
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , 3000);
    })
  }
  tableAction(data:any, action: string) {
    if (action === 'edit') {
      this.router.navigate(['/add-user', data.id]);
    }
    if (action === 'delete') {
      this.deleteUser(data);
    }
    if(action === 'view'){
      let queryParams = {
        mode : 'view'
      }
      this.router.navigate(['/add-user', data.id], { queryParams: queryParams });
    }
  }

  deleteUser(data:any) {
    let payload = {
      isActive : data.id ? false : true,
    }
    console.log(data,"update")
    this.userManagementService.deleteByUserId(data?.id,payload).subscribe((res:any) => {
      if(res.success){
        this.openSnackBar([ 'Deleted Successfully '] , 3000);
        let page = data?.page ? data?.page :1;
        this.getAllUser(page);
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , 3000);
    })
  }
  pageHandler(event:any):void{
    console.log("page",event)
    this.getAllUser(event?.page,event?.limit, event?.orderSorting, event?.key)
  }
  clearSearchBox(event:any){
    this.getAllUser()
  }
  openSnackBar(data: string[], duration:number) {
    this.snackBar.openFromComponent(SnackBarComponent, { data, duration });
  }
  sortTable(event:any){
    this.getAllUser(event.page,10,event?.orderSorting,event?.key)
  }

}
